import React, { useState, useEffect } from "react"

import skinLesions from "../../images/skin-lesions.png"
import user1 from "../../images/users/katherine-cs.png"
import user2 from "../../images/users/peter-cs.png"
import user3 from "../../images/users/martha-cs.png"
import user4 from "../../images/users/gregory-cs.png"

const KitTabs = ({ children }) => {
  const [activeE, setActiveE] = useState("Tab1")
  const [initial, setInitial] = useState(false)

  const toggleCurrentActive = e => {
    let tc = e.currentTarget.dataset.id
    if (tc.length) {
      setActiveE(tc)
    } else {
      setActiveE("")
    }
    if (!initial) {
      setInitial(true)
    }
    e.preventDefault()
  }

  const getActiveE = name => {
    let cn = name
    if (cn === activeE) {
      return true
    } else {
      return false
    }
  }

  // const checkActiveClass = name => {
  //   let cn = name
  //   if (cn === activeE) {
  //     return ""
  //   } else {
  //     return "isMuted"
  //   }
  // }

  useEffect(() => {
    if (initial) {
      if (activeE.length) {
        let elements = document.getElementsByClassName(activeE)
        let ot = ""
        let iw = typeof window !== "undefined" ? window.innerWidth : ""
        if (iw > 1024) {
          window.scrollTo({
            top: elements[0].offsetTop - 20,
            behavior: "smooth",
          })
        } else {
          window.scrollTo({
            top: elements[0].offsetTop - 20,
            behavior: "smooth",
          })
        }
      }
    } else {
      return false
    }
  }, [activeE])

  const TabButton = ({ tabId, text }) => (
    <button
      data-id={tabId}
      onClick={toggleCurrentActive}
      className={getActiveE(tabId) ? "isActive" : ""}
    >
      <p>{text}</p>
    </button>
  )

  return (
    <div className="kit-tabs lg_mt-1 mt-1 kit-tabs-related-conditions">
      <div className="row kit-tabs__tab-deck small-collapse medium-uncollapse desktopOnly">
        <div className="columns column_btn small-12 medium-expand stretch mt-1">
          <TabButton
            tabId="Tab1"
            text="Adult-onset mastocytosis in the skin (MIS)/cutaneous mastocytosis (CM)"
          />
        </div>
        <div className="columns column_btn small-12 medium-expand stretch">
          <TabButton tabId="Tab2" text="Mast cell activation syndrome (MCAS)" />
        </div>

        <div className="columns column_btn small-12 medium-expand stretch mt-1">
          <TabButton tabId="Tab3" text="Anaphylaxis" />
        </div>

        <div className="columns column_btn small-12 medium-expand stretch mt-1">
          <TabButton tabId="Tab4" text="Gastrointestinal (GI) disorders" />
        </div>

        <div className="columns column_btn small-12 medium-expand stretch mt-1">
          <TabButton tabId="Tab5" text="Myeloproliferative disorders" />
        </div>
      </div>
      <br className="show-for-large" /> <br className="show-for-large" />
      <br className="show-for-large" />
      <br className="show-for-large" />
      <br />
      <div className="row kit-tabs__tabs-deck kit-tabs-related-condtions__tabs-deck">
        <div className="tabs_deck_col">
          <div className="mobileOnly">
            <TabButton
              tabId="Tab1"
              text="Adult-onset mastocytosis in the skin (MIS)/cutaneous mastocytosis (CM)"
            />
          </div>
          {getActiveE("Tab1") && (
            <>
              {/* tabby */}
              <div className="columns Tab1">
                <div className="related-conditions-tab-card">
                  <p className="h2 bold mt-2 mb-2 purple">
                    Adult-onset mastocytosis in the skin (MIS)/cutaneous
                    mastocytosis (CM)
                  </p>
                  <p className="font22 bold purple">
                    <span className="powder font36 text--watch-quinn">
                      ~90%
                    </span>{" "}
                    of patients presenting with adult-onset mastocytosis in the
                    skin may have SM<sup>5,6</sup>
                  </p>
                  <img
                    src={skinLesions}
                    alt="Various skin lesion presentations associated with systemic mastocytosis (SM)"
                    className="centered_div skinLesionsImg"
                    width="100%"
                    height="auto"
                  />
                  <br />
                  <br />
                  <p className="color--purple lead text-center purple">
                    <strong>
                      Various skin lesion presentations associated with SM
                      <sup>5</sup>
                    </strong>
                  </p>
                  <p className="bodyP purple tightened-line-height">
                    Skin lesions typical of mastocytosis can exhibit swelling,
                    reddening, or pruritus, which can occur when mast cells are
                    activated, such as by stroking skin lesions (Darier’s sign)
                    or changes in temperature.<sup>4,7,8</sup>
                  </p>
                  <p className="bodyP purple tightened-line-height">
                    In adult patients with SM who have skin lesions, the lesions
                    are typically monomorphic maculopapular lesions (known as
                    MPCM or UP) and present as small, round, and pigmented.
                    <sup>7</sup> The number of lesions may vary among patients,
                    ranging from a few to possibly widespread coverage.
                    <sup>7</sup>
                  </p>
                  <p className="bodyP purple">
                    Other subvariants include DCM and mastocytoma.<sup>7,9</sup>
                  </p>
                  <div className="imgText_div">
                    <div>
                      <img
                        src={user1}
                        alt="Katherine, a hypothetical female indolent systemic mastocytosis (ISM) patient"
                        width="100%"
                        height="auto"
                        className="userImg"
                      />
                      <p className="not_actual_patient_footnote">
                        Not an actual patient.
                      </p>
                    </div>
                    <p className="">
                      <a href="/sm-profiles-case-studies/" className="powder">
                        Review a hypothetical case study
                      </a>{" "}
                      of a patient presenting with skin lesions ultimately
                      diagnosed with SM.
                    </p>
                  </div>
                  <br />
                  <ul className="hanging-ref color--purple">
                    <li>
                      DCM=diffuse cutaneous mastocytosis; MPCM=maculopapular
                      cutaneous mastocytosis; UP=urticaria pigmentosa.
                    </li>
                  </ul>
                </div>
              </div>
              {/* tabby */}
            </>
          )}
        </div>

        <div className="tabs_deck_col">
          <div className="mobileOnly">
            <TabButton
              tabId="Tab2"
              text="Mast cell activation syndrome (MCAS)"
            />
          </div>
          {getActiveE("Tab2") && (
            <>
              {/* tabby */}
              <div className="columns Tab2">
                <div className="related-conditions-tab-card">
                  <p className="h2 bold mt-2 mb-2 purple">
                    Mast cell activation syndrome (MCAS)
                  </p>
                  <p className="bodyP purple">
                    MCAS can be primary, secondary, or idiopathic. Primary MCAS
                    is a clonal disorder usually involving KIT D816V and
                    includes SM. A bone marrow biopsy may be required to confirm
                    a diagnosis of SM in patients living with primary MCAS.
                    <sup>8</sup>
                  </p>

                  <div className="imgText_div">
                    <div>
                      <img
                        src={user2}
                        alt="A hypothetical case study of a patient originally diagnosed with MCAS but later changed to SM."
                        width="100%"
                        height="auto"
                        className="userImg"
                      />
                      <p className="not_actual_patient_footnote">
                        Not an actual patient.
                      </p>
                    </div>
                    <p className="">
                      <a href="/sm-profiles-case-studies/" className="powder">
                        Review a hypothetical case study
                      </a>{" "}
                      of a patient originally diagnosed with MCAS but later
                      changed to SM.
                    </p>
                  </div>
                </div>
              </div>
              {/* tabby */}
            </>
          )}
        </div>

        <div className="tabs_deck_col">
          <div className="mobileOnly">
            <TabButton tabId="Tab3" text="Anaphylaxis" />
          </div>
          {getActiveE("Tab3") && (
            <>
              {/* tabby */}
              <div className="columns Tab3">
                <div className="related-conditions-tab-card">
                  <p className="h2 bold mt-2 mb-2 purple">Anaphylaxis</p>
                  <p className="bodyP purple">
                    Patients with SM may be at higher risk of anaphylaxis that
                    could be life-threatening.<sup>10</sup>
                  </p>
                </div>
              </div>
              {/* tabby */}
            </>
          )}
        </div>

        <div className="tabs_deck_col">
          <div className="mobileOnly">
            <TabButton tabId="Tab4" text="Gastrointestinal (GI) disorders" />
          </div>
          {getActiveE("Tab4") && (
            <>
              {/* tabby */}
              <div className="columns Tab4">
                <div className="related-conditions-tab-card">
                  <p className="h2 bold mt-2 mb-2 purple">
                    Gastrointestinal (GI) disorders
                  </p>
                  <p className="bodyP purple">
                    Patients with SM may experience GI symptoms that resemble
                    those of other GI disorders like <strong>IBS</strong> and{" "}
                    <strong>IBD</strong>.<sup>11</sup> These symptoms are
                    observed in up to 80% of SM patients. GI symptoms in SM
                    patients can occur due to the release of mast cell mediators
                    or the infiltration of mast cells, which may also lead to{" "}
                    <strong>malabsorption</strong> and{" "}
                    <strong>weight loss</strong>.<sup>11,12</sup>
                  </p>
                  <div className="imgText_div">
                    <div>
                      <img
                        src={user3}
                        alt="A hypothetical case study of a patient with unexplained anaphylaxis who is later diagnosed with SM."
                        width="100%"
                        height="auto"
                        className="userImg"
                      />
                      <p className="not_actual_patient_footnote">
                        Not an actual patient.
                      </p>
                    </div>
                    <p className="">
                      <a href="/sm-profiles-case-studies/" className="powder">
                        Review a hypothetical case study
                      </a>{" "}
                      of a patient with recurrent gastrointestinal symptoms who
                      is later diagnosed with SM.
                    </p>
                  </div>
                  <br />
                  <ul className="hanging-ref color--purple">
                    <li>
                      IBD=inflammatory bowel disease; IBS=irritable bowel
                      syndrome.
                    </li>
                  </ul>
                </div>
              </div>
              {/* tabby */}
            </>
          )}
        </div>

        <div className="tabs_deck_col">
          <div className="mobileOnly">
            <TabButton tabId="Tab5" text="Myeloproliferative disorders" />
          </div>
          {getActiveE("Tab5") && (
            <>
              {/* tabby */}
              <div className="columns Tab5">
                <div className="related-conditions-tab-card">
                  <p className="h2 bold mt-2 mb-2 purple">
                    Myeloproliferative disorders
                  </p>
                  <p className="bodyP purple">
                    ~70% of patients with advanced SM have an associated
                    neoplasm (CMML, MPN, MDS).<sup>13</sup>
                  </p>

                  <div className="imgText_div">
                    <div>
                      <img
                        src={user4}
                        alt="A hypothetical case study of a patient originally diagnosed with CMML who was later revised to SM-AHN, where the AHN component was CMML."
                        width="100%"
                        height="auto"
                        className="userImg"
                      />
                      <p className="not_actual_patient_footnote">
                        Not an actual patient.
                      </p>
                    </div>
                    <p className="">
                      <a href="/sm-profiles-case-studies/" className="powder">
                        Review a hypothetical case study
                      </a>{" "}
                      of a patient originally diagnosed with CMML who was later
                      revised to SM-AHN,
                      <br className="show-for-large" /> where the AHN component
                      was CMML.
                    </p>
                  </div>
                  <br />
                  <ul className="hanging-ref color--purple">
                    <li>
                      CMML=chronic myelomonocytic leukemia; MDS=myelodysplastic
                      syndrome; MPN=myeloproliferative neoplasm; SM-AHN=systemic
                      mastocytosis with an associated hematologic neoplasm.
                    </li>
                  </ul>
                </div>
              </div>
              {/* tabby */}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default KitTabs
