import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ReferenceBlock from "../../components/template-partials/reference-block"
import FooterCrown from "../../components/template-partials/footer-crown"
import KitTabsRelatedConditions from "../../components/template-partials/kit-tabs-related-conditions"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const RelatedConditionsPage = () => (
  <Layout route="recognizing-sm/diagnosis">
    <Seo
      page="test-kit"
      title="Related Conditions"
      description="Learn how to differentiate between the symptoms of systemic mastocytosis (SM) and other
disorders that present similarly to SM."
    />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Symptoms of systemic mastocytosis (SM) may be mistaken as other
          disorders and conditions,{" "}
          <span className="powder">leading to diagnostic delays</span>
          <sup>1,2</sup>
        </h1>

        <p className="h2 bold mt-3 mb-3 purple">
          It is important to recognize the symptoms of SM to help{" "}
          <span className="powder">
            reduce the time to diagnosis and treatment
          </span>
        </p>
        <p className="purple bodyP">
          Historically, it has taken ~<strong>6 years</strong> for patients
          living with SM to receive a diagnosis and often involves a diverse
          group of specialists.
          <sup>1,3</sup>{" "}
          <Link to="/recognizing-sm/symptoms">
            <span className="powder underline">Symptoms of SM</span>
          </Link>{" "}
          include <strong>skin lesions</strong>, <strong>diarrhea</strong>, and{" "}
          <strong>anaphylaxis</strong>, and can be perceived as a variety of
          related disorders.<sup>2,4</sup>
        </p>
      </div>
    </div>

    <div className="row lg_mt-1 align-center medium-align-left">
      <div className="columns small-12 text-center">
        <p className="color--purple lead purple bodyP">
          <strong>
            Expand to see how these disorders are different from SM
          </strong>
        </p>
      </div>
    </div>

    <div>
      <KitTabsRelatedConditions />
    </div>

    <div className="row lg_mt-10 mt-5 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple mobileBodyP">
          Once you suspect SM, initiate testing
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/diagnosing-sm/"
        >
          DIAGNOSING SM <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />

    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Mesa RA et al. <em>Cancer</em>. 2022;128(20):3691-3699.
        </li>
        <li>
          Theoharides TC et al. <em>N Engl J Med</em>. 2015;373(2):163-172.
        </li>
        <li>
          Russell N et al. <em>J Allergy Clin Immunol Pract</em>.
          2019;7(4):1157-1165.
        </li>
        <li>
          Pardanani A. <em>Am J Hematol</em>. 2023;98(7):1097-1116.
        </li>
        <li>
          Berezowska S et al. <em>Mod Pathol</em>. 2014;27(1):19-29. Reprinted
          from <em>Modern Pathology</em>, Vol 27, Berezowska S et al,
          Adult-onset mastocytosis in the skin is highly suggestive of systemic
          mastocytosis, Pages 19-29, Copyright 2014, with permission from
          Elsevier.
        </li>
        <li>
          Fuchs D et al. <em>J Allergy Clin Immunol Pract</em>.
          2021;9(4):1705-1712.e4.
        </li>
        <li>
          Hartmann K et al. <em>J Allergy Clin Immunol</em>. 2016;137(1):35-45.
        </li>
        <li>
          Gülen T et al. <em>J Allergy Clin Immunol Pract</em>.
          2021;9(11):3918-3928.
        </li>
        <li>
          WHO Classification of Tumours Editorial Board. Haematolymphoid tumours
          [Internet]. Lyon (France): International Agency for Research on
          Cancer; 2024 [cited April 24, 2024]. (
          <em>WHO Classification of Tumours Series</em>, 5th ed.; vol. 11).
          Available from: https://tumourclassification.iarc.who.int/chapters/63
        </li>
        <li>
          Jennings SV et al. <em>Immunol Allergy Clin North Am</em>.
          2018;38(3):505-525.
        </li>
        <li>
          Zanelli M et al. <em>Cancers (Basel)</em>. 2021;13(13):3316.
        </li>
        <li>
          Bedeir A et al.<em> Am J Surg Pathol</em>. 2006;30(11):1478-1482.
        </li>
        <li>
          Reiter A et al. <em>Blood</em>. 2020;135(16):1365-1376.
        </li>
         
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default RelatedConditionsPage
